<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-10, .cls-11, .cls-4, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 1492.08"
                    :y1="doorTopHeight1 + 7501.38"
                    :x2="doorLeftWidth1 + 1492.08"
                    :y2="doorTopHeight1 + 7473.34"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1457.19"
                    :y1="doorTopHeight1 + 7402.86"
                    :x2="doorLeftWidth1 + 1485.41"
                    :y2="doorTopHeight1 + 7402.86"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1499.33"
                    :y1="doorTopHeight1 + 7402.86"
                    :x2="doorLeftWidth1 + 1526.97"
                    :y2="doorTopHeight1 + 7402.86"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1492.08"
                    :y1="doorTopHeight1 + 7332.65"
                    :x2="doorLeftWidth1 + 1492.08"
                    :y2="doorTopHeight1 + 7292.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 1502.8"
                    :y1="doorTopHeight1 + 7323.32"
                    :x2="doorLeftWidth1 + 1502.8"
                    :y2="doorTopHeight1 + 7297.53"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1481.2"
                    :y1="doorTopHeight1 + 7323.37"
                    :x2="doorLeftWidth1 + 1481.2"
                    :y2="doorTopHeight1 + 7297.53"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1474.5"
                    :y1="doorTopHeight1 + 7327.75"
                    :x2="doorLeftWidth1 + 1474.5"
                    :y2="doorTopHeight1 + 7307.01"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 1509.56"
                    :y1="doorTopHeight1 + 7327.75"
                    :x2="doorLeftWidth1 + 1509.56"
                    :y2="doorTopHeight1 + 7306.97"
                    xlink:href="#glass-gradient"/>
    <g id="C10">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 1527},${doorTopHeight1 + 7473.34}
            h-69.85
            l0,28
            H${doorLeftWidth1 + 1527}
            Z
            m-4.89,23.13
            H${doorLeftWidth1 + 1462}
            v-18.24
            h60.08
            v18.24
            Z`"
            transform="translate(-1422.17 -7249.7)"/>
      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :d="`
            M${doorLeftWidth1 + 1457.19},${doorTopHeight1 + 7347.26}
            v111.19
            h28.22
            V${doorTopHeight1 + 7347.26}
            h-28.22
            Z
            m23.53,106.32
            h-18.66
            V${doorTopHeight1 + 7352.13}
            h18.66
            v101.45
            Z
            `"
            transform="translate(-1422.17 -7249.7)"/>
      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :d="`
            M${doorLeftWidth1 + 1499.33},${doorTopHeight1 + 7347.26}
            v111.19
            H${doorLeftWidth1 + 1527}
            V${doorTopHeight1 + 7347.26}
            h-27.64
            Z
            m22.11,106.32
            h-16.58
            V${doorTopHeight1 + 7352.13}
            h16.58
            v101.45
            Z
            `"
            transform="translate(-1422.17 -7249.7)"/>
      <g id="Rounded_glass" data-name="Rounded glass">
        <path id="inox-4"
              v-if="showInox"
              data-name="inox"
              class="cls-7"
              :d="`
              M${doorLeftWidth1 + 1527.11},${doorTopHeight1 + 7327.75}
              v4.9
              H${doorLeftWidth1 + 1457}
              v-5a35,35,0,0,1,70.07,0
              v0.05
              Z
              `"
              transform="translate(-1422.17 -7249.7)"/>
        <path id="glass"
              class="cls-8"
              :d="`
              M${doorLeftWidth1 + 1492.89},${doorTopHeight1 + 7297.53}
              a30.11,30.11,0,0,1,19.81,8.15
              l-0.07.07-17.57,17.57
              h0
              a9.5,9.5,0,0,0-2.17-.44
              v-25.36
              Z
              `"
              transform="translate(-1422.17 -7249.7)"/>
        <path id="glass-2"
              data-name="glass"
              class="cls-9"
              :d="`
              M${doorLeftWidth1 + 1471.33},${doorTopHeight1 + 7305.74}
              a30.11,30.11,0,0,1,19.75-8.21
              v25.37
              a9.56,9.56,0,0,0-2.13.47
              Z
              `"
              transform="translate(-1422.17 -7249.7)"/>
        <path id="glass-3"
              data-name="glass"
              class="cls-10"
              :d="`
              M${doorLeftWidth1 + 1483.48},${doorTopHeight1 + 7327.75}
              h-21.65
              v0
              a30.14,30.14,0,0,1,8.22-20.72
              l17.12,17.18
              a9.66,9.66,0,0,0-3.47,3.53
              v0
              `"
              transform="translate(-1422.17 -7249.7)"/>
        <path id="glass-4"
              data-name="glass"
              class="cls-11"
              :d="`
              M${doorLeftWidth1 + 1496.86},${doorTopHeight1 + 7324.12}
              L${doorLeftWidth1 + 1514},${doorTopHeight1 + 7307}
              l0.05,0
              a30.16,30.16,0,0,1,8.26,20.78
              h-21.81
              l-0.08-.14
              a9.66,9.66,0,0,0-3.5-3.48
              h0
              Z
              `"
              transform="translate(-1422.17 -7249.7)"/>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
